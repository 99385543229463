import React, { useEffect, useState, useRef } from "react";
import DataService from "../../services/data.service";
import { toast } from "react-toastify";
const AddAddressBlock = () => {
  const form = useRef();
  const [addresstype, setAddresstype] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [alternatephone, setAlternatePhone] = useState("");
  const [landmark, setLandmark] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [mint, setMint] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionTwo, setSelectedOptionTwo] = useState("");
  const [selectedOptionThree, setSelectedOptionThree] = useState("");
  const [mintTwo, setMintTwo] = useState([]);
  const [states, setStates] = useState([]);

  const onChangeAddressType = (e) => {
    const data = e.target.value;
    setAddresstype(data);
    console.log(addresstype);
  };
  const onChangeFirstName = (e) => {
    const data = e.target.value;
    setFirstName(data);
  };
  const onChangeLastName = (e) => {
    const data = e.target.value;
    setLastName(data);
  };
  const onChangeAddress = (e) => {
    const data = e.target.value;
    setAddress(data);
  };

  const onChangePhone = (e) => {
    const data = e.target.value;
    setPhone(data);
  };
  const onChangeAlternatePhone = (e) => {
    const data = e.target.value;
    setAlternatePhone(data);
  };

  const onChangeReion = (e) => {
    const data = e.target.value;
    setRegion(data);
  };
  const onChangeLandmark = (e) => {
    const data = e.target.value;
    setLandmark(data);
  };

  const onChangeZipcode = (e) => {
    const data = e.target.value;
    setZipcode(data);
  };
  const handleChange = (e) => {
    const data = e.target.value;
    setGender(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    data.type = addresstype;
    data.first_name = firstName;
    data.last_name = lastName;
    data.phone = phone;
    data.phone_alt = alternatephone;
    data.city = selectedOptionThree;
    data.country = selectedOption;
    data.state = selectedOptionTwo;
    data.zipcode = zipcode;
    data.address = address;
    data.landmark = landmark;
    data.gender = gender;
    data.region = region;

    DataService.addAddress(data).then(
      () => {
        setLoading(false);
        toast.success("Address added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);

        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  React.useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    DataService.getCountries().then((data) => {
      setMint(data.data.data);
      // console.log(data.data.data);
    });
  };

  const getstates = (asi) => {
    setLoading(true);
    DataService.getStates(asi).then((data) => {
      setStates(data.data.data);
    });
  };

  const getcity = (xyz) => {
    setLoading(true);
    DataService.getCity(xyz).then((data) => {
      setMintTwo(data.data.data);
    });
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setCountry(selectedOption);
    getstates(e.target.value);
  };

  const handleOptionTwo = (e) => {
    setSelectedOptionTwo(e.target.value);
    getcity(e.target.value);
  };
  const handleOptionChangeThree = (e) => {
    setSelectedOptionThree(e.target.value);
    getcity(e.target.value);
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close meCross"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body mbody px-3 px-lg-5">
            <form onSubmit={handleSubmit} ref={form}>
              <div class="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="fw-bold">Add Address</h2>
                </div>
              </div>
              <div className="row g-2 g-lg-3 mt-1 mt-lg-1">
                <div className="col-lg-6 p-0 addPField">
                  <select
                    required
                    onChange={onChangeAddressType}
                    className="form-control_address  bg-light-grey border-0 rounded-0 f-16"
                  >
                    <option value="">Address Type</option>
                    <option value="home">Home</option>
                    <option value="office">Office</option>
                  </select>
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    required
                    onChange={onChangeFirstName}
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="First Name"
                  />
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    onChange={onChangeLastName}
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <select
                    required
                    onChange={handleChange}
                    className="form-control_address  bg-light-grey border-0 rounded-0 f-16"
                  >
                    <option value="">Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    onChange={onChangePhone}
                    maxLength={10}
                    required
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    onChange={onChangeAlternatePhone}
                    maxLength={10}
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="Alternate Phone Number"
                  />
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    required
                    onChange={onChangeAddress}
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="Address"
                  />
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    onChange={onChangeLandmark}
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="Landmark"
                  />
                </div>
                <div className="col-lg-6 p-0 addPField">
                  <input
                    type="text"
                    onChange={onChangeZipcode}
                    className="form-control_address bg-light-grey border-0 rounded-0 f-16"
                    placeholder="Zipcode"
                  />
                </div>

                <div className="col-lg-6 p-0 addPField">
                  <select
                    required
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="form-control_address  bg-light-grey border-0 rounded-0 f-16"
                  >
                    <option value="defaultOptionValue">Select Country</option>

                    {mint && mint.length > 0
                      ? mint.map((option, index) => {
                          return (
                            <>
                              <option key={index} value={option.id}>
                                {option.name}
                              </option>
                            </>
                          );
                        })
                      : ""}
                  </select>
                </div>

                <div className="col-lg-6 p-0 addPField">
                  <select
                    required
                    value={selectedOptionTwo}
                    onChange={handleOptionTwo}
                    className="form-control_address  bg-light-grey border-0 rounded-0 f-16"
                  >
                    <option value="defaultOptionValue">Select States</option>

                    {states && states.length > 0
                      ? states.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>

                <div className="col-lg-6 p-0 addPField">
                  <select
                    required
                    value={selectedOptionThree}
                    onChange={handleOptionChangeThree}
                    className="form-control_address  bg-light-grey border-0 rounded-0 f-16"
                  >
                    <option value="defaultOptionValue">Select City</option>

                    {mintTwo && mintTwo.length > 0
                      ? mintTwo.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.name}
                            </option>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-center px-lg-5 px-2">
                <button type="submit" className="addRessSub btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddressBlock;
