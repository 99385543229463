import React, { useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import "../customCss/Register.css";
import Logo from "../images/logo.svg";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginHeader from "../common/LoginHeader";
import LoginFooter from "../common/LoginFooter";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from "react-router-dom";
import Footer from "../common/Footer";

function Register() {
  const form = useRef();
  const buttonref1 = useRef();
  const navigate = useNavigate();

  const [country, setCountry] = useState('')

  // const options = useMemo(() => countryList().getData(), [])

  // const changeHandler = country => {
  //   setCountry(country)
  // }

  const [loading, setLoading] = useState(false);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [role, setRole] = useState("");
  const [phoneCode, setPhoneCode] = useState('44');




  const onChangeFirstName = (e) => {
    const first_name = e.target.value;
    setFirst_name(first_name);
  };
  const onChangeLastName = (e) => {
    const last_name = e.target.value;
    setLast_name(last_name);
  };
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  }
  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  }
  const onChangePassword2 = (e) => {
    const password2 = e.target.value;
    setPassword2(password2);
  }
  const onChangeCompanyName = (e) => {
    const company_name = e.target.value;
    setCompany_name(company_name);
  }
  const changeRole = (e) => {
    const type = e.target.value;
    setRole(type);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    //check if password is matched..
    if (password === password2) {
      const data = {};
      data.last_name = last_name
      data.first_name = first_name
      data.email = email
      data.phone = phoneCode;
      data.company_name = company_name
      data.password = password
      data.password2 = password2
      data.country = country
      data.role = role
      setLoading(true);
      await AuthService.register(data).then(
        () => {
          setLoading(false);
          toast.success('Profile create successfully!. Please check your Email to verify account.', {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate('/login-page')

          // buttonref1.current.click();
        },
        (error) => {
          const resMessage = error?.response?.data?.msg;

          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    } else {
      toast.error("Password confirm pasword do not match!", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  return (
    <>
      {/* <!-- ################################# NAVBAR-SECTION-START ################################### --> */}
      <LoginHeader />
      {/* <!-- ################################# NAVBAR-SECTION-END ################################### --> */}


      <section class="form-section_register py-2">
        <div class="container">
          <div className="main_register">
            <form class="register" onSubmit={handleSubmit} ref={form}>
              <div class="row mb-2">
                <div class="col-5 p-0 text-end">

                </div>
                <div class="col-7 p-0 check-form">
                  {/* <Select options={options}
                    country={country}
                    onChange={changeHandler} /> */}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 p-0 text-end">
                  <label>Please select trade role:</label>
                </div>
                <div class="col-7 p-0 check-form">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      value="user"
                      checked={role === "user"}
                      onChange={changeRole}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Buyer
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      value="vendor"
                      checked={role === "vendor"}
                      onChange={changeRole}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Seller
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault3"
                      value="both"
                      checked={role === "both"}
                      onChange={changeRole}
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Both
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-5 p-0 text-end">
                  <label>Email Address:</label>
                </div>
                <div class="col-7 p-0">
                  <div class="input-field_register">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Please set the email as the login name."
                      onChange={onChangeEmail}


                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 p-0 text-end">
                  <label>Login Password:</label>
                </div>
                <div class="col-7 p-0">
                  <div class="input-field_register">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Set the login password"
                      onChange={onChangePassword}

                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 p-0 text-end">
                  <label>Confirm Password:</label>
                </div>
                <div class="col-7 p-0">
                  <div class="input-field_register">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Enter the login password again"
                      onChange={onChangePassword2}

                    />
                  </div>
                </div>
              </div>
              {(role === "vendor" || role === "both") && (
                <div class="row mb-2">
                  <div class="col-5 p-0 text-end">
                    <label>Company Name:</label>
                  </div>
                  <div class="col-7 p-0">
                    <div class="input-field_register">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Please enter your company name"
                        onChange={onChangeCompanyName}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div class="row mb-2">
                <div class="col-5 p-0 text-end">
                  <label>Full name:</label>
                </div>
                <div class="col-7 p-0">
                  <div class="inner_re row">
                    <div class="col-6 ps-0 pe-0">
                      <div class="input-field_register">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          onChange={onChangeFirstName}
                        />
                      </div>
                    </div>
                    <div class="col-6 pe-0">
                      <div class="input-field_register">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Last Name"
                          onChange={onChangeLastName}

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5 p-0 text-end">
                  <label>Tel:</label>
                </div>
                <div class="col-7 p-0">
                  <div class="input-field_country">
                    <PhoneInput
                      country={"uk"}
                      value={phoneCode}
                      defaultCountry="UK"
                      onChange={setPhoneCode}
                      className="new_country"

                    />

                  </div>
                  {/* <div class="inner_re row">
                    <div class="col-md-3 col-6 ps-0">
                      <div class="input-field_register">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="91"
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-6 p-0 mb-3">
                      <div class="input-field_register">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="area"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 col-12 pe-md-0 pe-0 ps-md-2 ps-0">

                    </div>
                  </div> */}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5"></div>
                <div class="col-7 p-0 check-form last">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      I agree to (a) <a href="/">Free Membership Agreement,</a>{" "}
                      (b)
                      <a href="/">Terms of Use,</a> and (c){" "}
                      <a href="/">Privacy Policy</a> . I agree to receive more
                      information from ChadApp.com about its products and
                      services.
                    </label>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-5"></div>
                <div class="col-7 p-0">
                  <div class="register-btn">

                    <button type="submit" class=" btn-secondary">
                      Agree and Register
                    </button>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>



      {/* <!-- ################################# FOOTER-SECTION-START ################################### -->   */}

      <Footer />
    </>
  );
}

export default Register;
