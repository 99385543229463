import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cart_Product from "../images/Cart_product.jpg";
import Product_gif from "../images/paymentgf.gif";
import visa from "../images/visa.svg";
import master from "../images/master.svg";
import tt from "../images/tt.svg";
import paypal from "../images/paypal.svg";
import pay from "../images/pay.svg";
import gpay from "../images/gpay.svg";
import online from "../images/online.svg";
import "../customCss/PaymentPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleDollarToSlot,
  faLocationDot,
  faHouse,
  faCommentDots,
  faCircleExclamation,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import Myaddresses from "./address/myaddresses";
import DataService from "../services/data.service";
import "../customCss/ProfileData.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
function PaymentPage() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buynow, setBuynow] = useState(false);

  useEffect(() => {
    getAddress();
  }, []);

  const getAddress = async () => {
    setLoading(true);
    await DataService.getAddress()
      .then((data) => {
        setLoading(false);
        setData(data?.data?.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  return (
    <>
      <Header />
      <div>
        <div className="container my-3 my-lg-5">
          <div className="payment_body my-3 my-lg-5">
            <div className="row">
              <div className="col-md-12 mb-md-0 mb-3">
                <div className="payment_left_top bg-white rounded-1 p-3 mb-3">
                  <div className="payment_left_top-popup">
                    <div className="payment_left_top-popup-btn py-4 text-center">
                      {loading ? (
                        <div className="col-lg-6 m-auto">
                          {loading && (
                            <div className="main_spinner">
                              <div
                                class="spinner-border text-warning"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <Myaddresses data={data} buynow={buynow} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PaymentPage;
