import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import { useNavigate } from "react-router-dom";
import "../customCss/AddToCart.css";
import "../customCss/ChatNow.css";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const ShippingPage = () => {
  const navigate = useNavigate();
  const auth = AuthService.getCurrentUser();
  React.useEffect(() => {
    document.title = "Cart";
    window.scrollTo(0, 0);
  }, []);

  const [cartData, setCartData] = useState({});
  const [totalAmount, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const addressId = JSON.parse(localStorage?.getItem("addressId2"));
  const addressId2 = JSON.parse(localStorage?.getItem("addressId"));
  const [totalShipping, setTotalShipping] = useState("");
  const handleShipping = () => {
    const data = {
      shipping_address_id: addressId,
      currency: "GBP",
    };

    DataService.createShippingCost(data).then(
      (res) => {
        const shipObj = res?.data;
        const updatedCartItems = shipObj.map((item) => {
          const shipping = item?.shipping?.find(
            (ship) => ship?.currencyType === "PULCL"
          );
          const shippingCost = shipping ? shipping?.price : 0;
          const itemTotal =
            item?.row?.price * item?.row?.quantity +
            shippingCost * item?.row?.quantity;

          return {
            ...item,
            shippingCost,
            totalAmount: itemTotal,
          };
        });
        setCartItems(updatedCartItems);
        const grandTotal = updatedCartItems.reduce(
          (acc, curr) => acc + curr?.totalAmount,
          0
        );
        const totalShippingAmount = updatedCartItems.reduce(
          (acc, curr) => acc + curr?.shippingCost * curr?.row?.quantity,
          0
        );
        setTotal(grandTotal);
        setTotalShipping(totalShippingAmount);
        handleUpdateCart(updatedCartItems);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  useEffect(() => {
    handleShipping();
  }, [addressId]);

  const handleRedirect = () => {
    if (auth) {
      handleSubmit();
    } else {
      toast.warn("You need to login first!!");
      setTimeout(() => {
        navigate("/login-page?url=payment-page");
      }, 1500);
    }
  };
  const [newTotal, setnewTotal] = useState(0);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    setLoading(false);
    setProduct(cartData);
  }, [cartData]);
  const calculateAmounts = (item) => {
    const shippingAmount =
      item?.shipping?.find((ship) => ship?.currencyType === "PULCL")?.price ||
      0;
    const totalShippingCost = shippingAmount * (item?.row?.quantity || 0);
    const totalAmount =
      totalShippingCost + (item?.row?.price || 0) * (item?.row?.quantity || 0);

    return { totalShippingCost, totalAmount };
  };
  const handleSubmit = () => {
    const data = {
      total_amount: Number(
        totalAmount?.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }) -
          totalShipping?.toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          })
      ),
      payment_method: "stripe",
      currency: "GBP",
      address_id: Number(addressId),
      billing_address_id: Number(addressId2),
      item_amount: 0,
      shipping_amount: Number(
        totalShipping?.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        })
      ),
      tax_amount: 0,
      sale_type: "cart",
    };
    DataService.createNewOrder(data).then(
      (res) => {
        localStorage.setItem("cxtyspiD", res?.data?.intent?.client_secret);
        navigate("/checkout");
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const handleUpdateCart = async (updatedCartItems) => {
    console.log(updatedCartItems)
    try {
      const updatePromises = updatedCartItems.map((item) => {
        const data = {
          shipping_amount: item.shippingCost * item?.row?.quantity,
          total_amount: item.totalAmount,
          tax_amount: 0,
          tax_percent: 0
        };
        return DataService.updateCart(data, item.row.id);
      });

      await Promise.all(updatePromises);
      // toast.success("Cart updated successfully!", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (error) {
      const resMessage =
        (error.response && error.response.data && error.response.data.msg) ||
        error.message ||
        error.toString();
      toast.error(`Error updating cart: ${resMessage}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <Tooltip id="my-tooltip" />
      <Header />
      <div className="container py-lg-5 px-lg-3 p-3">
        <div className="cart_body my-2">
          <h2 className="mb-3">Cost Details (Including Shipping Cost)</h2>
          <div className="row">
            <div className="col-md-8 me-4">
              {cartItems && cartItems.length > 0 ? (
                cartItems.map((item, cartindex) => {
                  const { totalShippingCost, totalAmount } =
                    calculateAmounts(item);
                  return (
                    <>
                      <div className="cart_left_down rounded-1 shadow">
                        <div className="cart_down_2">
                          <div className="row py-4 mx-3 align-items-center mob_column">
                            <div className="col-md-11">
                              <div className="cart_down_content_cart">
                                <div className="cart_down_content_2">
                                  <div className="row align-items-center mob_column">
                                    <div className="col-md-3">
                                      <div className="cart_product_name d-flex justify-content-between">
                                        <p className="text-truncate w-75">
                                          {item?.row?.Product?.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-2   price_sec">
                                      <p
                                        style={{ fontSize: "12px" }}
                                        className="mb-0"
                                      >
                                        Item Amount
                                      </p>
                                      <span className="piece_down_left">
                                        £{" "}
                                        {item?.row?.Product?.price_groups
                                          ? JSON.parse(
                                              item?.row?.Product?.price_groups
                                            ).map((bun, i) => {
                                              if (
                                                item?.row?.quantity >=
                                                  bun.min &&
                                                item?.row?.quantity <= bun.max
                                              ) {
                                                return bun.mrp;
                                              }
                                            })
                                          : item?.row?.Product?.cost_price}
                                      </span>
                                      {/* <span className="piece_down_right">
                                        {" "}
                                        {item?.row?.Product?.unit}
                                      </span> */}

                                      <br />
                                      {/* 
                              <span className="">
                                <del className="piece_down_right">
                                  USD 7.88 /Piece
                                </del>
                              </span> */}
                                    </div>
                                    <div className="col-md-2  button_sec_cart">
                                      <div className="button_all_cart d-flex justify-content-center ">
                                        {/* <button
                                        onClick={async () => {
                                          const _product = await Promise.all(
                                            product.map((item, index) => {
                                              return cartindex === index
                                                ? {
                                                    ...item,
                                                    quantity:
                                                      item?.row?.quantity > 1
                                                        ? item?.row?.quantity - 1
                                                        : 1,
                                                  }
                                                : item;
                                            })
                                          );
                                          setProduct(_product);
                                          updateQuantity(
                                            item?.row?.quantity - 1,
                                            item
                                          );
                                        }}
                                      >
                                        -
                                      </button> */}
                                        <input
                                          type="number"
                                          value={item?.row?.quantity}
                                          disabled
                                        />
                                        {/* <button
                                        onClick={async () => {
                                          const _product = await Promise.all(
                                            product.map((item, index) => {
                                              return cartindex === index
                                                ? {
                                                    ...item,
                                                    quantity: item?.row?.quantity + 1,
                                                  }
                                                : item;
                                            })
                                          );
                                          setProduct(_product);
                                          updateQuantity(
                                            item?.row?.quantity + 1,
                                            item
                                          );
                                        }}
                                      >
                                        +
                                      </button> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                      <p
                                        style={{ fontSize: "12px" }}
                                        className="mb-0"
                                      >
                                        Shipping Amount
                                      </p>
                                      <span className="piece_price_cart">
                                        {" "}
                                        £
                                      </span>
                                      {totalShippingCost.toFixed(2)}
                                    </div>

                                    <div className="col-md-2 total_cart_price">
                                      <div className="cart_total_price">
                                        <p
                                          style={{ fontSize: "12px" }}
                                          className="mb-0"
                                        >
                                          Total Amount
                                        </p>
                                        <span className="piece_price_cart">
                                          £ {totalAmount.toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cart_mainSec">
                        <div className="cart_sec"></div>
                        <div className="cart_bottomSec">
                          <div className="cart_actionSec">
                            <ul className="list-inline d-flex mb-0">
                              <li className="me-3"></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="no_product_main">
                  <p>Your cart is empty</p>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="cart_right_body rounded-1 shadow px-3 py-4">
                <h3>Cart Subtotal</h3>
                <div className="cart_total_items d-flex justify-content-between">
                  <div className="cart_total_head">
                    <span className="cart_right_head">Item(s) Total </span>
                  </div>
                  <div className="cart_total_items">
                    <span>
                      £{" "}
                      {totalAmount?.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      }) -
                        totalShipping?.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        })}
                    </span>
                  </div>
                </div>
                <div className="cart_total_items d-flex justify-content-between">
                  <div className="cart_total_head">
                    <span className="cart_right_head">
                      Total Shipping Amount{" "}
                    </span>
                  </div>
                  <div className="cart_total_items">
                    <span>
                      £{" "}
                      {totalShipping?.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="cart_total_price d-flex justify-content-between">
                  <div className="cart_total_head">
                    <span className="cart_right_head">Cart Total</span>
                  </div>
                  <div className="cart_total_price">
                    <span className="cart_price_span">
                      £{" "}
                      {totalAmount?.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                </div>
                <div className="cart_extra mb-3">
                  <span className="cart_right_head">
                    (Including shipping fee and tax)
                  </span>
                </div>
                <div className="cart_checkout">
                  <button onClick={handleRedirect} className="btn btn_checkout">
                    Complete Payment (£
                    {newTotal
                      ? newTotal.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        })
                      : totalAmount.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        })}
                    )
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShippingPage;
