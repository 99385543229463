import React, { useEffect, useState } from "react";
import HelperService from "../services/helper.service";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import "../customCss/Header.css";
// import Logo from "../images/logo.png";
import Flag from "../images/flag.png";
import FB from "../images/fb.svg";
import Google from "../images/google.svg";
import Linkdin from "../images/link.svg";
import Twiter from "../images/twitter.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  faMessage,
  faBars,
  faAngleDown,
  faUser,
  faClipboard,
  faCartShopping,
  faChevronRight,
  faHeart,
  faBell,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import SocialLogin from "./SocialLogin";
import GoogleTranslator from "./GoogleTranslator";

const Header = (data) => {
  const absoluteImagePath = "/assets/img/logo.png";

  const navigate = useNavigate();

  const auth = AuthService.getCurrentUser();

  const [cartCount, setCartCount] = useState(0);
  const [masterCatData, setMasterCatData] = useState([]);
  const [showLogin, setshowLogin] = useState(true);
  const [showLogout, setshowLogout] = useState(false);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [noticeCount, setNoticeCount] = useState(0);

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
    // document.title = "Profile";
    getProfile();
  }, []);

  const getProfile = () => {
    DataService.getUserDetail()
      .then((data) => {
        setProfile(data.data.data);
        setLoading(false);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT
        // });
      });
  };

  const getNotificationData = async () => {
    await DataService.getNotifications().then((data) => {
      if (data?.data?.data) {
        if (data?.data?.data.length && data?.data?.data.length > 0) {
          var filter = data?.data?.data.filter((item) => {
            return item.is_read == 0;
          });
          setNoticeCount(filter.length);
        }
      }
    });
  };
  useEffect(() => {
    if (auth) {
      getNotificationData();
    }
  }, []);

  // let token = JSON.parse(localStorage.getItem("user"));
  // console.log()
  const logout = (e) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };
  useEffect(() => {
    if (auth) {
      getNotifi();
      getProduct();
      setshowLogin(false);
      setshowLogout(true);
    } else {
      const response = HelperService.getLocalCart();
      setCartCount(response.length);
      setshowLogin(true);
      setshowLogout(false);
    }
  }, [data]);

  const getNotifi = async () => {
    await DataService.getCart("whislist")
      .then((data) => {
        setWishlistCount(data?.data?.data.length);
      })
      .catch((error) => {
        setWishlistCount(0);
      });
  };

  const getProduct = async () => {
    await DataService.getCart("cart")
      .then((data) => {
        setCartCount(data?.data?.data.length);
      })
      .catch((error) => {
        setCartCount(0);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const userId = auth ? auth.id : "";
    await DataService.getHomePageData(userId).then((data) => {
      setMasterCatData(data.data.masterCategories);
    });
  };
  const [activeCategory, setActiveCategory] = useState(null); // To manage active accordion category

  const toggleAccordion = (index) => {
    setActiveCategory(activeCategory === index ? null : index); // Toggle open/close for each accordion
  };
  return (
    <>
      <header>
        <section className="navbar-section bg-white">
          <div className="container">
            <nav className="navbar navbar-expand-md">
              {/* <!----- Nav Logo -----> */}
              <div className="navbar-logo">
                <Link to="/" className="navbar-brand">
                  <img src={absoluteImagePath} alt="logo" />
                </Link>
              </div>

              {/* <!----- Category Dropdown Start-----> */}
              {/* <div className="category-dropdown">
                <button className="dropbtn">
                  <FontAwesomeIcon icon={faBars} />
                  Categories
                  <FontAwesomeIcon icon={faAngleDown} />
                </button>
                <div className="dropdown-content">
                  <ul>
                    {masterCatData && masterCatData.length > 0
                      ? masterCatData.map((item, i) => (
                        <li>
                          <Link to="#">{item.name}</Link>
                          <div className="right-arrow">
                            <FontAwesomeIcon icon={faChevronRight} />
                          </div>
                          <div className="side-content">
                            {item.Categories && item.Categories.length > 0 ? (
                              item.Categories.map((cat, j) => (
                                <div key={j} className="accordion-section">
                                  <div
                                    className="accordion-title"
                                    onClick={() => toggleAccordion(j)}
                                  >
                                    <h2>{cat.name}</h2>
                                    <FontAwesomeIcon icon={activeCategory === j ? faChevronDown : faChevronRight} />
                                  </div>

                                  {activeCategory === j && (
                                    <ul className="accordion-content">
                                      {cat.Categories && cat.Categories.length > 0 ? (
                                        cat.Categories.map((subcat, k) => (
                                          <li key={k}>
                                            <Link to={`/${item.slug}/${cat.slug}/${subcat.slug}`}>
                                              {subcat.name}
                                            </Link>
                                          </li>
                                        ))
                                      ) : (
                                        <p>No Subcategories Available</p>
                                      )}
                                    </ul>
                                  )}
                                </div>
                              ))
                            ) : (
                              <p>No Categories Available</p>
                            )}
                          </div>
                        </li>
                      ))
                      : (
                        <p>No Master Categories Available</p>
                      )}

                  </ul>
                </div>
              </div> */}
              {/* <!----- Category Dropdown End-----> */}

              {/* <!----- Nav Icons Start-----> */}
              <div className="navbar-icons ms-auto">
                <div className="user-dropdown">
                  <div className="dropbtn">
                    <FontAwesomeIcon icon={faUser} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </div>
                  <div className="dropdown-content">
                    {showLogout && (
                      <div className="user-head">
                        <h2>
                          Welcome {profile.first_name} {profile.last_name}{" "}
                        </h2>
                      </div>
                    )}
                    {showLogin && (
                      <div>
                        <div className="user-btn text-center mb-3">
                          <Link to="/login-page">Sign in</Link>
                          <Link to="https://vendor.dealitt.com/">
                            Sign in as Vendor
                          </Link>
                          <Link to="/register">Join For Free</Link>
                          {/* <span>Or</span> */}
                        </div>
                        {/* <div className="user-social my-3">
                          <SocialLogin></SocialLogin>
                        </div> */}
                      </div>
                    )}

                    {showLogout && (
                      <div>
                        <div className="user-btn text-center">
                          <Link
                            onClick={logout}
                            className="d-flex justify-content-between align-items-center bg-yellow text-white"
                          >
                            Logout{" "}
                            <i>
                              <svg
                                width="15"
                                height="13"
                                viewBox="0 0 15 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5 2C5 1.72386 5.22386 1.5 5.5 1.5L13.5 1.5C13.7761 1.5 14 1.72386 14 2V11C14 11.2761 13.7761 11.5 13.5 11.5H5.5C5.22386 11.5 5 11.2761 5 11V9C5 8.72386 4.77614 8.5 4.5 8.5C4.22386 8.5 4 8.72386 4 9V11C4 11.8284 4.67157 12.5 5.5 12.5H13.5C14.3284 12.5 15 11.8284 15 11V2C15 1.17157 14.3284 0.5 13.5 0.5L5.5 0.5C4.67157 0.5 4 1.17157 4 2V4C4 4.27614 4.22386 4.5 4.5 4.5C4.77614 4.5 5 4.27614 5 4V2Z"
                                  fill="#F6F5FA"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10.8536 6.85355C11.0488 6.65829 11.0488 6.34171 10.8536 6.14645L7.85355 3.14645C7.65829 2.95118 7.34171 2.95118 7.14645 3.14645C6.95118 3.34171 6.95118 3.65829 7.14645 3.85355L9.29289 6L0.5 6C0.223858 6 0 6.22386 0 6.5C0 6.77614 0.223858 7 0.5 7H9.29289L7.14645 9.14645C6.95118 9.34171 6.95118 9.65829 7.14645 9.85355C7.34171 10.0488 7.65829 10.0488 7.85355 9.85355L10.8536 6.85355Z"
                                  fill="#F6F5FA"
                                />
                              </svg>
                            </i>
                          </Link>
                          <Link to="https://vendor.dealitt.com/">
                            Sign in as Vendor
                          </Link>
                        </div>
                      </div>
                    )}

                    <div className="user-links">
                      <Link to="\" target="_blank">
                        My DEALITT
                      </Link>
                      <Link to="https://vendor.dealitt.com/" target="_blank">
                        Vendor Store
                      </Link>
                      <Link to="/myorders">Orders</Link>
                      <Link to="/whislist">Favorites</Link>
                      <Link to="/user-profile">Account</Link>
                    </div>
                  </div>
                </div>

                {/* <div className="msg-dropdown">
                  <Link to="/chats">
                    <button className="dropbtn">
                      <FontAwesomeIcon icon={faMessage} />
                      <FontAwesomeIcon icon={faAngleDown} />
                    </button>
                  </Link>
                </div> */}
                <Link to="/cart">
                  <div className="shopping-dropdown" id="cart-nav-item-li">
                    <button className="dropbtn">
                      <FontAwesomeIcon icon={faCartShopping} />
                    </button>
                    {cartCount > 0 ? (
                      <div className="notification_count">
                        <h2>{cartCount}</h2>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>

                <Link to="/notifications">
                  <div className="shopping-dropdown" id="cart-nav-item-li">
                    <button className="dropbtn">
                      <FontAwesomeIcon icon={faBell} />
                    </button>
                    {noticeCount > 0 && (
                      <div class="notification_count">
                        <h2>{noticeCount}</h2>
                      </div>
                    )}
                  </div>
                </Link>
              </div>

              {/* <Link to="/whislist">
                  <div className="shopping-dropdown" id="cart-nav-item-li">
                  <button className="dropbtn"><FontAwesomeIcon icon={faHeart} /><FontAwesomeIcon icon={faAngleDown} /></button>
                    {cartCount > 0 ? (
                      <div className="notification_count">
                        <h2>{cartCount}</h2>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Link> */}

              <Link to="/whislist">
                <div className="shopping-dropdown" id="cart-nav-item-li">
                  <button className="dropbtn">
                    <FontAwesomeIcon icon={faHeart} />
                  </button>
                  {wishlistCount > 0 ? (
                    <div className="notication_count2">
                      <h2>{wishlistCount}</h2>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Link>

              {/* <!----- Nav Icons Start -----> */}

              {/* <!----- Help Link Start -----> */}
              <div className="help-link ps-2 d-lg-block d-none">
                <div className="help-dropdown">
                  <button className="dropbtn">
                    Help
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  <div className="dropdown-content">
                    <h5>Let Us Help You</h5>
                    <Link to="/myorders">Track / View Orders</Link>
                    <Link to="/user-profile">My Account</Link>
                    <Link to="/return-policy">Return and Replacement</Link>
                  </div>
                </div>
              </div>
              {/* <!----- Help Link Start -----> */}

              {/* <!----- Language Link Start -----> */}
              <div className="lang-link px-2 d-lg-block d-none">
                <div className="lang-dropdown pe-2">
                  <button className="dropbtn">
                    English-GBP
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  {/* <div className="dropdown-content">
                    <div className="lang-head mb-3">
                      <h2>Region settings</h2>
                    </div>
                    <div className="lang-select">
                      <label>Language</label>
                      <GoogleTranslator />
                      <label>Currency</label>
                      <select
                        className="form-select mb-4"
                        aria-label="Default select example"
                      >
                        <option value="1">USD - US Dollar</option>
                        <option value="2">HKD - Hong Kong Dollar</option>
                        <option value="3">TOP - Tongan Pa'anga</option>
                        <option value="4">PYG - Paraguay Guarani</option>
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <!----- Language Link End -----> */}

              {/* <!----- More Link Start -----> */}
              <div className="more-link d-lg-block d-none">
                <div className="more-dropdown">
                  <button className="dropbtn">
                    More
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                  <div className="dropdown-content">
                    <div className="more-links">
                      <h5>DEALITT Payment Method</h5>
                      <Link to="/payment-transaction-guide">
                        Payment Method Help
                      </Link>
                      <Link to="/payment-methods">Payment Options</Link>
                    </div>
                    <div className="more-links">
                      <h5>Sell on DEALITT.com</h5>
                      <Link to="/blog">Blog</Link>
                      <Link to="/our-vision">Our Vision</Link>
                      <Link to="/our-story">Our Story</Link>
                      {/* <Link to="#">Letter of Credit</Link> */}
                    </div>
                    <div className="more-links">
                      <h5>Customer Services</h5>
                      {/* <Link to="/faq">Help Center</Link> */}
                      <Link to="/privacy">Policies and Rules</Link>
                      <Link to="/buy-and-sell-guide">How to sell and buy</Link>
                      <Link to="/abuse-policy">Report abuse</Link>
                    </div>
                    {/* <div className="more-links">
                      <h5>Get to Know Us</h5>
                      <Link to="#">Careers</Link>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <!----- More Link End -----> */}
            </nav>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
