import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./view/pages/Home";
import ViewProfile from "./view/profile/ViewProfile";
import SinglePage from "./view/pages/SinglePage";
import CategoryPage from "./view/pages/CategoryPage";
import UserProfile from "./view/pages/UserProfile";
import Favorite from "./view/pages/Favorite";
import AddToCart from "./view/pages/AddToCart";
import MyProfile from "./view/pages/component/UserProfileLayout/UserProfilePage/MyProfile";
import MemberProfile from "./view/pages/component/UserProfileLayout/UserProfilePage/MemberProfile";
import EditMemberProfile from "./view/pages/component/UserProfileLayout/UserProfilePage/EditMemberProfile";
import UserProfileMain from "./view/pages/component/UserProfileLayout/UserProfilePage/UserProfileMain";
import TaxInformation from "./view/pages/component/UserProfileLayout/UserProfilePage/TaxInformation";
import PrivacySettings from "./view/pages/component/UserProfileLayout/UserProfilePage/PrivacySettings";
import EmailPreferences from "./view/pages/component/UserProfileLayout/UserProfilePage/EmailPreferences";
import UploadMyPhoto from "./view/pages/component/UserProfileLayout/UserProfilePage/UploadMyPhoto";
import LoginPage from "./view/pages/LoginPage";
import ForgotPass from "./view/pages/ForgotPass";
import SeeWhatOthers from "./view/pages/component/UserProfileLayout/UserProfilePage/SeeWhatOthers";
import PaymentPage from "./view/pages/PaymentPage";
import Compare from "./view/pages/Compare";
import VendorStore from "./view/pages/VendorStore";
import MainCategory from "./view/pages/MainCategory";
import Register from "./view/pages/register";
import About from "./view/pages/About";
import Privacy from "./view/pages/privacy";
import SubCatagory from "./view/pages/SubCatagory";
import Faq from "./view/pages/Faq";
import { RestrictedAccess } from "./view/private/RestrictedAccess";
import AddWhislist from "./view/pages/AddWishlist";
import Blog from "./view/pages/blog";
import ContactUs from "./view/pages/ContactUs";
import CompareC from "./view/pages/Compare_copy";
import Blogdetails from "./view/pages/blog-details";
import AddressPage from "./view/pages/Address";
import Orders from "./view/pages/Myorders";
import Chats from "./view/pages/Chats";
import Notification from "./view/pages/Notification";
import Checkout from "./view/pages/checkout";
import OrderDetails from "./view/pages/checkout/order-details";
import InvoiceDetails from "./view/pages/checkout/invoice-details";
import ReportAbuse from "./view/pages/ReportAbuse";
import VendorContact from "./view/pages/VendorContact";
import VendorInnerProfile from "./view/pages/VendorProfile";

// import Careers from "./view/pages/Careers";
// import ApplyJob from "./view/pages/careers/applyJob";
// import Jobdiscription from "./view/pages/careers/jobdiscription";
// import Applyform from "./view/pages/careers/applyform";
import Dashboard from "./view/Dashboard/pages/dashboard";
import OrdersDashboard from "./view/Dashboard/pages/orders";
import RefundRequest from "./view/Dashboard/pages/RefundRequest";
import InvoiceDetailsDashboard from "./view/Dashboard/pages/invoice-details";
import ViewReview from "./view/Dashboard/pages/review";
import ReviewDetails from "./view/Dashboard/pages/review-detail";
import AccountDetails from "./view/Dashboard/pages/AccountDetails";
import Invoice from "./view/Dashboard/pages/invoice";

// /////   Vendor Dashboard
import VendorDashboard from "./view/VendorDashboard/pages/dashboard";
import Brand from "./view/VendorDashboard/pages/brand";
import ViewBrand from "./view/VendorDashboard/pages/view-brand";
import AddNewBrand from "./view/VendorDashboard/pages/add-new-brands";
import SignUpFields from "./view/VendorDashboard/pages/signup-field";
import VendorProfile from "./view/VendorDashboard/pages/myprofile";
import EditProfile from "./view/VendorDashboard/pages/edit-profile";
import VendorInvoice from "./view/VendorDashboard/pages/invoice";
import ViewProduct from "./view/VendorDashboard/pages/view-product";
import VendorViewReview from "./view/VendorDashboard/pages/review";
import VendorReviewDetails from "./view/VendorDashboard/pages/review-detail";
import VendorOrderDetails from "./view/VendorDashboard/pages/order-details";
import VendorInvoiceDetails from "./view/VendorDashboard/pages/invoice-details";
import Forgot from "./view/VendorDashboard/pages/Forgot";
import Resetpassword from "./view/VendorDashboard/pages/reset-password";
import VendorNotification from "./view/VendorDashboard/pages/notification";
import Company from "./view/VendorDashboard/pages/company";
import ManageProfile from "./view/VendorDashboard/pages/manage-profile";
import ManageCompany from "./view/VendorDashboard/section/home/Company/ManageCompany";
import EditCompany from "./view/VendorDashboard/section/home/Company/EditCompany";
import VendorChats from "./view/VendorDashboard/pages/Chats";
import Refund from "./view/VendorDashboard/pages/RefundRequest";
import CompanyAccount from "./view/VendorDashboard/pages/CompanyAccount";
import Otp from "./view/VendorDashboard/pages/otp";
import ForgotPassword from "./view/VendorDashboard/pages/forgot-password";
import AddProduct from "./view/VendorDashboard/pages/add-product";
import Product from "./view/VendorDashboard/pages/products";
import EditProduct from "./view/VendorDashboard/pages/edit-product";
import VendorOrders from "./view/VendorDashboard/pages/orders";
import UploadVideo from "./view/VendorDashboard/pages/UploadVideo";
import Vision from "./view/pages/Vision";
import OurStory from "./view/pages/OurStory";
import SellBuy from "./view/pages/SellBuy";
import PaymentTransactionGuide from "./view/pages/PaymentTransactionGuide";
import PaymentMethodsD from "./view/pages/PaymentsMethods";
import ReturnPolicy from "./view/pages/ReturnPolicy";
import AbusePolicy from "./view/pages/AbusePolicy";
import ActivateAccount from "./view/VendorDashboard/pages/activate-account";
import ShippingPage from "./view/pages/ShippingPage";
// /////   Vendor Dashboard

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password/:token" element={<Resetpassword />} />
        <Route path="/activate-account/:token" element={<ActivateAccount />} />
        <Route path="/login-page" element={<LoginPage />} />
        <Route path="/register" element={<Register />} />
        <Route exact path="/view-profile" element={<ViewProfile />} />
        <Route
          exa
          path="/:master/:category/:subcategoty/:id"
          element={<SinglePage />}
        />
        <Route exact path="/:master/:category/" element={<CategoryPage />} />
        <Route exact path="/favorite" element={<Favorite />} />
        <Route exact path="/cart" element={<AddToCart />} />
        <Route exact path="/shipping-page" element={<ShippingPage />} />
        <Route exact path="/whislist" element={<AddWhislist />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/complaints" element={<ReportAbuse />} />
        {/* <Route exact path="/careers" element={<Careers />} /> */}
        {/* <Route exact path="/apply-job" element={<ApplyJob />} /> */}
        {/* <Route exact path="/jobdiscription" element={<Jobdiscription />} /> */}
        {/* <Route exact path="/appply-form" element={<Applyform />} /> */}
        <Route exact path="/compare/:id" element={<Compare />} />
        <Route path="/checkout/:gateway?" element={<Checkout />} />
        <Route path="/order-details/:id" element={<OrderDetails />} />
        <Route path="/invoice-details/:id" element={<InvoiceDetails />} />
        <Route exact path="/compare-copy" element={<CompareC />} />
        <Route exact path="/vendor-store/:slug" element={<VendorStore />} />
        <Route exact path="/vendor-contact/:slug" element={<VendorContact />} />
        <Route
          exact
          path="/vendor-profile/:slug"
          element={<VendorInnerProfile />}
        />
        <Route exact path="/:master" element={<MainCategory />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/our-vision" element={<Vision />} />
        <Route exact path="/our-story" element={<OurStory />} />
        <Route exact path="/buy-and-sell-guide" element={<SellBuy />} />
        <Route
          exact
          path="/payment-transaction-guide"
          element={<PaymentTransactionGuide />}
        />
        <Route exact path="/payment-methods" element={<PaymentMethodsD />} />
        <Route exact path="/return-policy" element={<ReturnPolicy />} />
        <Route exact path="/abuse-policy" element={<AbusePolicy />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-details/:id" element={<Blogdetails />} />
        <Route exact path="/address" element={<AddressPage />} />

        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route path="/notifications" element={<Notification />} />
        <Route
          exact
          path="/:master/:category/:subcategory"
          element={<SubCatagory />}
        />
        <Route element={<RestrictedAccess />}>
          <Route exact path="/myorders" element={<Orders />} />
          <Route exact path="/payment-page" element={<PaymentPage />} />
          <Route exact path="/user-profile" element={<UserProfile />}>
            <Route exact path="" element={<UserProfileMain />} />
            <Route exact path="my-profile" element={<MyProfile />} />
            <Route exact path="member-profile" element={<MemberProfile />} />
            <Route
              exact
              path="edit-member-profile"
              element={<EditMemberProfile />}
            />
            <Route exact path="text-information" element={<TaxInformation />} />
            <Route exact path="privacy-setting" element={<PrivacySettings />} />
            <Route
              exact
              path="email-preferences"
              element={<EmailPreferences />}
            />
            <Route exact path="upload-photo" element={<UploadMyPhoto />} />
            <Route exact path="see-what-other" element={<SeeWhatOthers />} />
          </Route>
          <Route exact path="/chats" element={<Chats />} />
          {/*----------------- dashboard ------------------*/}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard-order" element={<OrdersDashboard />} />
          <Route path="/refund-request" element={<RefundRequest />} />
          <Route exact path="/invoice" element={<Invoice />} />
          <Route
            path="/invoice-details/:id"
            element={<InvoiceDetailsDashboard />}
          />
          <Route exact path="/review" element={<ViewReview />} />
          <Route exact path="/review-details/:id" element={<ReviewDetails />} />
          <Route exact path="/Account-details" element={<AccountDetails />} />
          {/* -----------------dashboard -----------------*/}
          {/* ------------------vendor Dashboard---------------- */}
          <Route path="/vendor-dashboard" element={<VendorDashboard />} />
          <Route path="/Upload-video" element={<UploadVideo />} />
          <Route exact path="/otp" element={<Otp />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route element={<RestrictedAccess />}>
            <Route exact path="/vendor-dashboard" element={<Dashboard />} />
            <Route exact path="/add-product" element={<AddProduct />} />
            <Route exact path="/products" element={<Product />} />
            <Route exact path="/view-product/:id" element={<ViewProduct />} />
            <Route exact path="/edit-product/:id" element={<EditProduct />} />
            <Route exact path="/orders" element={<VendorOrders />} />
            <Route exact path="/refund-request" element={<Refund />} />
            <Route exact path="/company-account" element={<CompanyAccount />} />
            <Route exact path="/brand" element={<Brand />} />
            <Route exact path="/brand-details/:id" element={<ViewBrand />} />
            <Route exact path="/add-brand" element={<AddNewBrand />} />
            <Route exact path="/edit-brand/:id" element={<AddNewBrand />} />
            <Route exact path="/sign-up-fields" element={<SignUpFields />} />
            <Route exact path="/my-profile" element={<VendorProfile />} />
            <Route exact path="/edit-profile/:id" element={<VendorProfile />} />
            <Route exact path="/vendor-invoice" element={<VendorInvoice />} />
            <Route
              exact
              path="/vendor-invoice-details/:id"
              element={<VendorInvoiceDetails />}
            />
            <Route
              exact
              path="/order-details/:id"
              element={<VendorOrderDetails />}
            />
            <Route exact path="/vendor-review" element={<VendorViewReview />} />
            <Route
              exact
              path="/review-details/:id"
              element={<VendorReviewDetails />}
            />
            <Route
              exact
              path="/notification"
              element={<VendorNotification />}
            />
            <Route exact path="/edit-company-profile" element={<Company />} />
            <Route
              exact
              path="/manage-company-info"
              element={<ManageProfile />}
            />
            <Route exact path="/add-company" element={<ManageCompany />} />
            <Route exact path="/edit-company" element={<EditCompany />} />
            <Route exact path="/chats" element={<VendorChats />} />
          </Route>
          {/* ------------------vendor Dashboard---------------- */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
