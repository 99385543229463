import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import DataService from "../../services/data.service";
import HelperService from "../../services/helper.service";
import { toast } from "react-toastify";
import AddAddressBlock from "./addAddress";
import AuthService from "../../services/auth.service";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const MyAddresses = (props) => {
  const navigate = useNavigate();
  const [addressId, setaddressId] = useState("");
  const [addressId2, setaddressId2] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [cartData, setCartData] = useState({});
  const [totalAmount, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const auth = AuthService.getCurrentUser();
  React.useEffect(() => {
    initCart();
  }, []);
  const initCart = () => {
    if (!auth) {
      getCartLocal();
    } else {
      getProduct();
    }
  };
  const handleCheckboxChange = () => {
    setaddressId2(addressId);
    setIsChecked(!isChecked);
  };
  const [shippingAmount, setShippingAmount] = useState(0);
  const addressChange = (e) => {
    setaddressId(e.target.value);
    setShippingAmount(
      HelperService.getShippingRate(e.target.getAttribute("data-region"))
    );
  };
  const addressChange2 = (e) => {
    setaddressId2(e.target.value);
  };
  const navigateToNextPage = async (e) => {
    e.preventDefault();
    if (addressId) {
      var a2 = addressId2;
      if (isChecked) {
        a2 = addressId;
        setaddressId2(addressId);
      }
      if (a2) {
        localStorage.setItem("addressId", JSON.stringify(addressId));
        localStorage.setItem("addressId2", JSON.stringify(a2));
        localStorage.setItem("shippingAmount", JSON.stringify(shippingAmount));
        // handleShipping();
        // handleSubmit();
        if (props.buynow) {
          navigate("/shipping-page");
        } else {
          navigate("/shipping-page");
        }
      } else {
        toast.error("Please select billing address", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Please select shipping address", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleShipping = () => {
    const data = {
      shipping_address_id: addressId2,
      currency: "GBP",
    };
    DataService.createShippingCost(data).then(
      (res) => {
        const shipObj = res?.data?.[0]?.shipping;
        const finalShip = shipObj.find(
          (item) => item?.currencyType === "PULCL"
        );
        localStorage.setItem("shipxty", JSON.stringify(finalShip));
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getProduct = async () => {
    await DataService.getCart("cart")
      .then((data) => {
        setLoading(false);
        const response = data?.data?.data;
        let total = 0;
        response.map((value) => {
          let unitPrice = () => {
            // for (const range of JSON.parse(value?.Product?.price_groups)) {
            //     if (value.quantity >= range.min && value.quantity <= range.max) {
            //         return range.mrp * value.quantity;
            //     }
            // }
          };
          let price = isNaN(unitPrice())
            ? value.price * value.quantity
            : unitPrice();
          total = total + price;
        });
        setTotal(total);
        setCartData(data?.data?.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  const getCartLocal = async () => {
    let total = 0;
    setLoading(false);
    const response = HelperService.getLocalCart();
    await Promise.all(
      response.map(async (value, i) => {
        let unitPrice = () => {
          // for (const range of JSON.parse(value?.Product?.price_groups)) {
          //     if (value.quantity >= range.min && value.quantity <= range.max) {
          //         return range.mrp * value.quantity;
          //     }
          // }
        };
        let price = value?.Product?.price_groups
          ? unitPrice()
          : value.price * value.quantity;
        total = total + price;
        //get each product from db..
        await DataService.getProductDetail(value.product_id, "")
          .then((data) => {
            if (data?.data?.category) {
              response[i].Product = data?.data?.category;
            }
          })
          .catch((error) => {});
      })
    );
    setLoading(false);
    setTotal(total);
    setCartData(response);
  };
  const handleSubmit = () => {
    const data = {
      total_amount: totalAmount,
      payment_method: "stripe",
      currency: "GBP",
      address_id: Number(addressId),
      billing_address_id: Number(addressId2),
      item_amount: 0,
      shipping_amount: 0,
      tax_amount: 0,
      sale_type: "cart",
    };
    DataService.createNewOrder(data).then(
      (res) => {
        localStorage.setItem("cxtyspiD", res?.data?.intent?.client_secret);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  return (
    <div className="row  m-0">
      <div className="col-md-12 col-12 ">
        <div className="block mt-2 mt-lg-5 ms-lg-3 ms-0 ps-5 d-flex mb-3 ">
          <div className="block-title w-100">
            <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row border-bottom pb-3">
              <div>
                <h2 className="Shipping_add">Select Your Shipping Address </h2>
              </div>
              <div>
                <NavLink
                  to="#"
                  className="add_address"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                  Add Address
                </NavLink>
              </div>
            </div>
            {props.data && props.data.length > 0
              ? props.data.map((item, i) => (
                  <div className="select-address-list d-flex align-items-center relative">
                    <label
                      className="form-check-label w-100 cursor-pointer"
                      htmlFor={item.id}
                    >
                      <div className="d-flex align-items-top mt-1 mt-lg-3 p-3 address-main border-bottom rounded-0">
                        <div className="radio-btn">
                          <input
                            className="form-check-input radio"
                            onChange={addressChange}
                            type="radio"
                            name="address"
                            id={item.id}
                            data-region={item.region}
                            value={item.id}
                          />
                          <span className="checkmark"></span>
                        </div>
                        <div className="flex-grow-1">
                          <div className="address-tag">
                            {item.type == "office" ? "Office" : "Home"}
                          </div>
                          <div className="d-flex">
                            <div className="place-name">
                              {item.first_name} {item.last_name}
                            </div>
                            <div className="phone-number">{item.phone}</div>
                          </div>
                          <div className="address-detail1">
                            {item.address} {item.city} {item.region}
                            <br></br>
                            {item.landmark}
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                ))
              : ""}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm-12 ps-2 ms-2 ps-lg-5 ms-lg-5">
            <div class="form-check">
              <label class="form-check-label" for="addresscheck">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="addresscheck"
                  name="addresscheck"
                  value="addresscheck"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                Billing address should be same as Shipping address
              </label>
            </div>
          </div>
        </div>
        {!isChecked && (
          <div className="block mt-2 mt-lg-5 ms-lg-3 ms-0 ps-5 d-flex mb-3 ">
            <div className="block-title w-100">
              <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row border-bottom pb-3">
                <div>
                  <h2 className="Shipping_add">Select Your Billing Address </h2>
                </div>
                <div>
                  <NavLink
                    to="#"
                    className="add_address"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    Add Address
                  </NavLink>
                </div>
              </div>
              {props.data && props.data.length > 0
                ? props.data.map((itemin, i) => (
                    <div className="select-address-list d-flex align-items-center relative">
                      <label
                        className="form-check-label w-100 cursor-pointer"
                        htmlFor={`billing_` + itemin.id}
                      >
                        <div className="d-flex align-items-top mt-3 p-3 address-main border-bottom rounded-0">
                          <div className="radio-btn2">
                            <input
                              className="form-check-input radio"
                              onChange={addressChange2}
                              type="radio"
                              name="address_billing"
                              id={`billing_` + itemin.id}
                              value={itemin.id}
                            />
                            <span className="checkmark"></span>
                          </div>
                          <div className="flex-grow-1">
                            <div className="address-tag">
                              {itemin.type == "office" ? "Office" : "Home"}
                            </div>
                            <div className="d-flex">
                              <div className="place-name">
                                {itemin.first_name} {itemin.last_name}
                              </div>
                              <div className="phone-number">{itemin.phone}</div>
                            </div>
                            <div className="address-detail1">
                              {itemin.address} {itemin.city} {itemin.region}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        )}
        <div className="col-12 d-flex justify-content-end mt-4 mb-1">
          {" "}
          <Link
            onClick={navigateToNextPage}
            type="button"
            class="btn btn-primary goToCheckout"
          >
            Proceed to Shipping Page
          </Link>
        </div>
      </div>

      <AddAddressBlock></AddAddressBlock>
    </div>
  );
};

export default MyAddresses;
