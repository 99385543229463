import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(
  "pk_test_51PpUfuRt1hlGrWiS40wTq0B8TyEEQ7CeEQw18qkWP6Vx59kw1z493i0nfACApemZWvw6JIY0qKQfkChiyrDSHejL00rGSZs32e"
);
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://dealitt.com/myorders",
      },
    });

    if (error) {
      toast.error("Payment failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      toast.success("Payment successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      localStorage.removeItem("cxtyspiD");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const paymentIntentStatus = query.get("payment_intent_client_secret");

    if (paymentIntentStatus) {
      localStorage.removeItem("cxtyspiD");
      toast.success("Payment successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, []);


  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <br />
      <button
        className="btn btn-primary"
        type="submit"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        {isLoading ? (
          <span className="spinner-border spinner-border-sm"></span>
        ) : (
          "Pay Now"
        )}
      </button>
    </form>
  );
};

const PaymentWrapper = (props) => {
  const clientSecret = localStorage.getItem("cxtyspiD")

  // const [shippingAmount, setShippingAmount] = useState(0);
  // const createPaymentIntent = async () => {
  //   let tAmount = props.totalAmount + shippingAmount;

  //   const paymentData = {
  //     total_amount: tAmount,
  //     currency: "GBP",
  //   };

  //   try {
  //     const response = await axios.post(
  //       "https://api.dealitt.com/api/stripe/makePayment",
  //       paymentData
  //     );
  //     setClientSecret(response?.data?.data?.client_secret);
  //   } catch (error) {
  //     toast.error("Failed to create Payment Intent.", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   const shipping_amount = localStorage.getItem("shippingAmount");
  //   setShippingAmount(shipping_amount ? JSON.parse(shipping_amount) : 0);
  // }, []);


  // Wait until clientSecret is available
  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentForm />
    </Elements>
  );
};

export default PaymentWrapper;
