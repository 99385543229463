import React, { Fragment, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const Myorders = (props) => {
  const [expandedSuborders, setExpandedSuborders] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Simulating an API call or data fetching
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating a loading delay of 2 seconds
  }, []);
  const toggleSuborder = (orderId) => {
    setExpandedSuborders((prevExpandedSuborders) => {
      if (prevExpandedSuborders.includes(orderId)) {
        return prevExpandedSuborders.filter((id) => id !== orderId);
      }
      return [...prevExpandedSuborders, orderId];
    });
  };
  const toggleSuborderclose = (orderId) => {
    setExpandedSuborders((prevExpandedSuborders) => {
      if (prevExpandedSuborders.includes(orderId)) {
        return prevExpandedSuborders.filter((id) => id !== orderId);
      }
      return [...prevExpandedSuborders, orderId];
    });
  };
  useEffect(() => {
    localStorage.removeItem("cxtyspiD");
  }, [])
  return (
    <div className="block  p-lg-5 p-3">
      <div className="block-title">
        <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
          <div>
            <h2>My Orders</h2>
          </div>
        </div>
        {loading ? (
          <div className="main_spinner">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {props?.data && props?.data?.length > 0
              ? props?.data?.map((item, i) => {
                return (
                  <>
                    <div className="d-flex align-items-top mt-3 p-3 address-main gap-3 orders_sec" key={item.id}>

                      <div class="product-img1 pb-3">
                        <img src={item.OrderItems[0]?.Product?.file_path ? "https://api.dealitt.com/" + item.OrderItems[0]?.Product?.file_path : ""} alt="product" srcset="" width="200" />
                        {item.OrderItems.length > 1 ?
                          <span className="order_more" onClick={() => toggleSuborder(item.id)}>{item.OrderItems.length - 1} More Items</span> : ''
                        }
                      </div>

                      <div className="title justify-content-between">
                        <div className="product-title">
                          <h4>
                            {item.OrderItems[0]?.Product?.name}
                          </h4>
                          {/* <div className="address-tag">
                            4.6 <i class="fas fa-star"></i>
                          </div> */}
                        </div>
                        <div className="price">
                          <h5>
                          £{item.OrderItems[0].price.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                          </h5>
                        </div>
                        <div className="view_order_eye">
                          <Link to={"/order-details/" + item.id}>
                            <button>Check Details</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {expandedSuborders.includes(item.id) && (

                      <div className="orders_expand">
                        <button className="filter_cross_orders" onClick={() => toggleSuborderclose(item.id)}>X</button>
                        {item.OrderItems && item.OrderItems.length > 0 ?
                          item.OrderItems.slice(1).map((item, i) => (
                            <div className="d-flex align-items-top mt-3 p-3 address-main gap-3 orders_sec align-items-center" key={item.id}>
                              <div class="product-img1">
                                <img src={item?.Product?.file_path ? "https://api.dealitt.com/" + item?.Product?.file_path : ""} alt="product" srcset="" width="200" />
                              </div>
                              <div className="title justify-content-between">
                                <div className="product-title">
                                  <h4>
                                    {item?.Product?.name}

                                  </h4>
                                  {/* <div className="address-tag">
                                    4.6 <i class="fas fa-star"></i>
                                  </div> */}
                                </div>
                                <div className="price">
                                  <h5>
                                  £{item.price.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                                  </h5>
                                </div>
                                <div className="dlivery-date">
                                  {/* <p>
                                      Delivered on Jan 23
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          )) : ""

                        }
                      </div>

                    )}
                  </>)
              }) : <div className="no_product_main">
                <p>Your Orders list is empty</p>
              </div>}
          </>
        )}
        {/* <div className="cancel_order_btn">
          <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" >Cancel Order</button>
        </div>
        <div className="cancel_order">
          <div className="modal fade" id="exampleModalEdit" tabindex="-1" aria-labelledby="exampleModalEditLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h1 className="modal-title fs-5" id="exampleModalEditLabel"><Link to="/">Orders</Link></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body px-3 px-lg-5">
                  <div className="cancel_inner">
                    <div className="main_top_cancel">
                      <h2>Order#235 → Order Items</h2>
                    </div>

                    <div className="inner_cancel_order">

                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <b>Items</b>
                        </div>
                        <div className="col-lg-1">
                          <b>Cost</b>
                        </div>
                        <div className="col-lg-1">
                          <b>Qty</b>
                        </div>
                        <div className="col-lg-1">
                          <b>Total</b>
                        </div>
                        <div className="col-lg-1">
                          <b>Tax</b>
                        </div>
                      </div>
                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <p>Beans</p>
                        </div>
                        <div className="col-lg-1">
                          <p>$59</p>
                        </div>
                        <div className="col-lg-1">
                          <p>1</p>
                        </div>
                        <div className="col-lg-1">
                          <p>$59</p>
                        </div>
                        <div className="col-lg-1">
                          <p>$12</p>
                        </div>
                      </div>

                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <p>Dissount[?]:</p>
                        </div>
                        <div className="col-lg-4">
                          <p>$0</p>
                        </div>
                      </div>

                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <p>Shipping[?]:</p>
                        </div>
                        <div className="col-lg-4">
                          <p>$0</p>
                        </div>
                      </div>
                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <p>Tax:</p>
                        </div>
                        <div className="col-lg-4">
                          <p>$12</p>
                        </div>
                      </div>
                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <p>Order Total:</p>
                        </div>
                        <div className="col-lg-4">
                          <p>$71</p>
                        </div>
                      </div>
                      <div className="row same_top_cancel">
                        <div className="col-lg-8">
                          <p className="red_text">Refunded:</p>
                        </div>
                        <div className="col-lg-4">
                          <p className="red_text">-$0</p>
                        </div>
                      </div>

                      <div className="refund_btn">
                        <button>Requets Refund</button>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Myorders;