import React from "react";
import WhereDeals from "../../../images/dealitt where deals are made.jpeg";
import "../../../customCss/Vision.css";
import AspirationOne from "../../../images/dealitt we support fashion.jpeg";
import AspirationTwo from "../../../images/dealit-aspiration-two.jpg";

const VisionDealit = () => {
  return (
    <>
      <section class="visionSec">
        <div class="container my-5 vision_flexSec">
          <div className="row justify-content-between align-items-center g-4">
            <div className="col-sm-5">
              <img className="w-100" src={WhereDeals} alt="dealitt where deals are made" />
            </div>
            <div className="col-sm-6">
              <h2>DEALITT Vision</h2>
              <p>
                A clear vision is essential for success, guiding us toward our
                goals and contributing to the challenges of the world. As the
                CEO of DEALITT puts it, “It is hard for me to live without
                contributing to the world.” A strong vision keeps the company
                moving forward and motivates individuals to achieve their
                targets and aspirations. When building something as critical as
                a chip, having a vision and values is essential; otherwise,
                progress becomes aimless.
              </p>
              <p>
                The vision of DEALITT is to enhance the global digital
                e-commerce system. We aim to achieve this by advancing the
                technology that facilitates communication between buyers and
                sellers. This requires meticulous attention to detail and a deep
                understanding of market needs and challenges. Additionally, our
                vision includes adapting technology to suit different markets
                worldwide. DEALITT’s vision can be summarized as follows:
              </p>
            </div>
          </div>
        </div>
        <div class="container my-5 vision_boxSec">
          <div className="row justify-content-between g-4 align-items-stretch">
            <div className="col-sm-4">
              <div className="vision_boxInner">
                <i class="fas fa-check-circle"></i>
                <h4>Empower businesses of all sizes globally</h4>
                <p>
                  We aspire to help factories in poorer countries improve their
                  economies, thereby increasing business value.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="vision_boxInner">
                <i class="fas fa-check-circle"></i>
                <h4>Enhance e-commerce technology</h4>
                <p>
                  By improving communication between buyers and sellers, we
                  elevate the overall experience and efficiency of digital
                  transactions.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="vision_boxInner">
                <i class="fas fa-check-circle"></i>
                <h4>Build a resilient system</h4>
                <p>
                  Our system is designed to adapt to various challenges and
                  economic risks, and to be applicable across different
                  countries.
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-sm-12">
              <p className="text-center">
                In summary, our goal is to add value at every stage, ensuring
                that all our solutions are sustainable and socially responsible.
              </p>
            </div>
          </div>
        </div>
        <div className="vision_flexAspSec">
          <div class="container py-5 ">
            <div className="row justify-content-between align-items-stretch">
              <div className="col-sm-6">
                <h2>DEALITT Aspiration</h2>
                <p>
                  Our purpose and aspiration are to improve society by enhancing
                  the economies of businesses in various countries. We carefully
                  consider social outcomes and how society will receive our
                  offerings. Our relentless focus on technology improvement and
                  the integration of digital innovation into our systems aims to
                  boost customer satisfaction. We believe that children are the
                  future, and we are committed to developing technology and
                  promoting products that do not harm them.
                </p>
                <p>
                  Continuous improvement is at the core of our daily work. We
                  strive for an inclusive and resilient society by enabling a
                  sustainable economy with strong returns on investment. Part of
                  our vision and aspiration is to support the local community in
                  the Middle East, providing jobs in areas where companies are
                  unable to establish offices. We believe that every community
                  deserves the opportunity to learn, and as part of our
                  aspiration to expand into different regions, we are committed
                  to improving education and the economy. At DEALITT, we believe
                  it is not fair to concentrate offices in one area in Europe,
                  as it fails to address global issues and instead exacerbates
                  capitalism.
                </p>
                <p>
                  We are acutely aware of the challenges that people may face in
                  their work, including racism and unfair work-life balance.
                  Therefore, part of our aspiration at DEALITT is to foster
                  diversity, inclusion, and equality as we continue on our
                  journey. Building a robust team that embraces these concepts
                  requires continuous improvement. Stronger retention, greater
                  depth of experience, and ongoing skill and knowledge
                  development are key.
                </p>
                <p>
                  To realize our vision and aspiration, we must continue
                  investing in all the core elements of excellence: excellent
                  people, supported by technology, innovation, and professional
                  networks. We hold Excellence Weeks, supported by webinars and
                  new innovative ideas throughout the company. Excellence in
                  continuous improvement is achieved through an ongoing
                  understanding of market problems, societal needs, and future
                  vision. This kind of analysis and interpretation will guide us
                  in improving our services to meet future needs without harming
                  society, in alignment with our values.
                </p>
              </div>
              <div className="col-sm-5 position-relative">
                <img
                  className="posOne"
                  src={AspirationOne}
                  alt="dealitt low cost"
                />
                <img
                  className="posTwo"
                  src={AspirationTwo}
                  alt="dealitt-aspiration-Two"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container bottom_blockSec py-5 ">
          <div className="row pb-2">
            <div className="col-sm-12 text-center">
              <h2>Our Values</h2>
              <p className="text-center">
                Our core values are underpinned by seven key principles, forming
                the foundation of our company policy to ensure that our services
                uphold these values.
              </p>
            </div>
          </div>
          <div className="row justify-content-center g-4 align-items-stretch">
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>01.</h3>
                <h4>DEAL One: Respect and Integrity</h4>
                <p>
                  Respect for individuals is paramount at DEALITT, and it is the
                  most important value within our company.
                </p>
                <ul>
                  <li> We treat everyone with respect. </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>02.</h3>
                <h4>DEAL Two: Support</h4>
                <p>
                  Regardless of the challenges our employees face, we work hard
                  and collaborate to support each other as a team.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>03.</h3>
                <h4>DEAL Three: Society</h4>
                <p>
                  We value people, which is why diversity, inclusion, and
                  equality are integral to our policy.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>04.</h3>
                <h4>DEAL Four: Sustainability</h4>
                <p>
                  We ensure that the services we provide in any country do not
                  negatively impact the environment, society, or economy.
                </p>
                <ul>
                  <li>
                    We consider how our services and technology might affect the
                    children in the countries where we operate.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>05.</h3>
                <h4>DEAL Five: Technology and Innovation</h4>
                <p>
                  Continuous improvement in our technology is essential to
                  maintaining high performance.
                </p>
                <ul>
                  <li>
                    As part of our policy, we believe technology should be
                    developed in a way that supports societal development.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>06.</h3>
                <h4>DEAL Six: Continuous Development</h4>
                <p>
                  We foster curiosity to innovate and develop our technology and
                  services.
                </p>
                <ul>
                  <li>
                    We connect people, knowledge, and experience to deliver the
                    best outcomes.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="vision_bottomInner">
                <h3>07.</h3>
                <h4>DEAL Seven: Resilience</h4>
                <p>
                  Our team does not give up when faced with technical or
                  non-technical problems. We support each other to keep
                  performing at our best.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisionDealit;
