import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import HelperService from "../services/helper.service";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import { useNavigate, Link } from "react-router-dom";
import Cart_Product from "../images/Cart_product.jpg";
import "../customCss/AddToCart.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../customCss/ChatNow.css";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const AddToCart = () => {
  const navigate = useNavigate();
  const auth = AuthService.getCurrentUser();
  React.useEffect(() => {
    document.title = "Cart";
    window.scrollTo(0, 0);
    initCart();
  }, []);

  const [cartData, setCartData] = useState({});
  const [totalAmount, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const initCart = () => {
    if (!auth) {
      getCartLocal();
    } else {
      getProduct();
    }
  };
  // const handleClosePopup = () => {
  //   props.setShowPopup(false);
  // };
  const handleRedirect = () => {
    if (auth) {
      navigate("/payment-page");
    } else {
      toast.warn("You need to login first!!");
      setTimeout(() => {
        navigate("/login-page?url=payment-page");
      }, 1500);
    }
  };
  const getProduct = async () => {
    await DataService.getCart("cart")
      .then((data) => {
        setLoading(false);
        const response = data?.data?.data;
        let total = 0;
        response.map((value) => {
          let unitPrice = () => {
            for (const range of JSON.parse(value?.Product?.price_groups)) {
              if (value.quantity >= range.min && value.quantity <= range.max) {
                return range.mrp * value.quantity;
              }
            }
          };
          let price = value?.Product?.price_groups
            ? unitPrice()
            : value.price * value.quantity;
          total = total + price;
        });
        setTotal(total);
        setCartData(data?.data?.data);
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };

  const getCartLocal = async () => {
    let total = 0;
    setLoading(false);
    const response = HelperService.getLocalCart();
    await Promise.all(
      response.map(async (value, i) => {
        let unitPrice = () => {
          for (const range of JSON.parse(value?.Product?.price_groups)) {
            if (value.quantity >= range.min && value.quantity <= range.max) {
              return range.mrp * value.quantity;
            }
          }
        };
        let price = value?.Product?.price_groups
          ? unitPrice()
          : value.price * value.quantity;
        total = total + price;
        //get each product from db..
        await DataService.getProductDetail(value.product_id, "")
          .then((data) => {
            if (data?.data?.category) {
              response[i].Product = data?.data?.category;
            }
          })
          .catch((error) => {});
      })
    );
    setLoading(false);
    setTotal(total);
    setCartData(response);
  };
  const [newTotal, setnewTotal] = useState(0);
  const [product, setProduct] = useState([]);

  const removeCart = (item) => {
    setLoading(true);
    deleteCart(item);
  };
  const whislist = (item) => {
    if (!auth) {
      navigate("/login-page?url=cart");
    } else {
      const data = {};
      data.type = "whislist";
      updateCart(data, item, true);
    }
  };

  const updateQuantity = (e, item) => {
    if (e <= 0) {
      e = 1;
    }
    if (!auth) {
      HelperService.setLocalCart(item, e);
      initCart();
    } else {
      const data = {};
      data.quantity = e;
      updateCart(data, item);
    }
  };
  const updateCart = (data, item, wishlist = false) => {
    DataService.updateCart(data, item.id).then(
      async () => {
        setLoading(false);
        initCart();
        // toast.success("Product Moved to WishList", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const deleteCart = (item) => {
    if (!auth) {
      HelperService.deleteLocalCart(item.product_id);
      initCart();
      toast.success("Product deleted from cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      DataService.deleteCart(item.id).then(
        () => {
          initCart();
          toast.success("Product deleted from cart!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    }
  };
  useEffect(() => {
    setLoading(false);
    setProduct(cartData);
  }, [cartData]);
  return (
    <>
      <Tooltip id="my-tooltip" />
      <Header />
      <div className="container py-lg-5 px-lg-3 p-3">
        <div className="cart_body my-2">
          <h2>Cart</h2>
          <div className="row">
            <div className="col-md-8 me-4">
              {product && product.length > 0 ? (
                product.map((item, cartindex) => (
                  <>
                    <div className="cart_left_down rounded-1 shadow">
                      <div className="cart_down_2">
                        <div className="row py-4 mx-3 align-items-lg-center align-items-start mob_column">
                          <div className="col-md-1 col-2 ps-2 d-flex justify-content-center">
                            <div className="cart_product_img">
                              {item?.Product?.file_path ? (
                                <img
                                  alt={item?.Product?.name}
                                  src={
                                    item.Product.file_path
                                      ? "https://api.dealitt.com/" +
                                        item.Product.file_path
                                      : ""
                                  }
                                ></img>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-11 col-10">
                            <div className="cart_down_content_cart">
                              <div className="cart_down_content_2">
                                <div className="row align-items-center mob_column">
                                  <div className="col-md-3">
                                    <div className="cart_product_name d-flex justify-content-between">
                                      <p className="text-truncate w-75">
                                        {item?.Product?.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-4   price_sec">
                                    <span className="piece_down_left">
                                      £{" "}
                                      {item?.Product?.price_groups
                                        ? JSON.parse(
                                            item?.Product?.price_groups
                                          ).map((bun, i) => {
                                            if (
                                              item.quantity >= bun.min &&
                                              item.quantity <= bun.max
                                            ) {
                                              return bun.mrp;
                                            }
                                          })
                                        : item?.Product?.cost_price}
                                    </span>
                                    {/* <span className="piece_down_right">
                                        {" "}
                                        {item?.Product?.unit}
                                      </span> */}

                                    <br />
                                    {/* 
                              <span className="">
                                <del className="piece_down_right">
                                  USD 7.88 /Piece
                                </del>
                              </span> */}
                                  </div>
                                  <div className="col-md-2 col-4  button_sec_cart">
                                    <div className="button_all_cart d-flex justify-content-center ">
                                      {/* <button
                                        onClick={async () => {
                                          const _product = await Promise.all(
                                            product.map((item, index) => {
                                              return cartindex === index
                                                ? {
                                                    ...item,
                                                    quantity:
                                                      item.quantity > 1
                                                        ? item.quantity - 1
                                                        : 1,
                                                  }
                                                : item;
                                            })
                                          );
                                          setProduct(_product);
                                          updateQuantity(
                                            item.quantity - 1,
                                            item
                                          );
                                        }}
                                      >
                                        -
                                      </button> */}
                                      <input
                                        type="text"
                                        value={`x${item.quantity}`}
                                        disabled
                                      />
                                      {/* <button
                                        onClick={async () => {
                                          const _product = await Promise.all(
                                            product.map((item, index) => {
                                              return cartindex === index
                                                ? {
                                                    ...item,
                                                    quantity: item.quantity + 1,
                                                  }
                                                : item;
                                            })
                                          );
                                          setProduct(_product);
                                          updateQuantity(
                                            item.quantity + 1,
                                            item
                                          );
                                        }}
                                      >
                                        +
                                      </button> */}
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-4 total_cart_price">
                                    <div className="cart_total_price">
                                      <span className="piece_price_cart">
                                        £{" "}
                                        {item?.Product?.price_groups
                                          ? JSON.parse(
                                              item?.Product?.price_groups
                                            ).map((bun, i) => {
                                              if (
                                                item.quantity >= bun.min &&
                                                item.quantity <= bun.max
                                              ) {
                                                return bun.mrp * item.quantity;
                                              }
                                            })
                                          : item?.price * item.quantity}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-1 cart_to_wishlist">
                                    <span
                                      data-bs-toggle="modal"
                                      data-bs-target={`#staticBackdrop2_${item.id}`}
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Move To WishList"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i class="fas fa-heart"></i>
                                      <div
                                        class="modal fade"
                                        id={`staticBackdrop2_${item.id}`}
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabindex="-1"
                                        aria-labelledby="staticBackdrop2Label"
                                        aria-hidden="true"
                                      >
                                        <div class="modal-dialog modal-dialog-centered">
                                          <div class="modal-content">
                                            <div class="modal-body py-3">
                                              <div className="d-flex flex-column justify-content-center align-items-center">
                                                <h5 className="mt-4">
                                                  Are you sure you want to move
                                                  this to wishlist ?
                                                </h5>
                                                <div className="d-flex justify-content-center mt-3">
                                                  <button
                                                    type="button"
                                                    class="main_button me-3"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => {
                                                      whislist(item);
                                                    }}
                                                  >
                                                    Yes
                                                  </button>
                                                  <button
                                                    type="button"
                                                    class="main_button red_button"
                                                    data-bs-dismiss="modal"
                                                  >
                                                    No
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div className="col-md-1 ">
                                    <div className="cart_cross">
                                      <span
                                        data-bs-toggle="modal"
                                        data-bs-target={`#staticBackdrop_${item.id}`}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Remove From Cart"
                                      >
                                        <FontAwesomeIcon icon={faXmark} />
                                        <div
                                          class="modal fade"
                                          id={`staticBackdrop_${item.id}`}
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabindex="-1"
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                        >
                                          <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                              <div class="modal-body py-3">
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                  <i className="text-center">
                                                    <svg
                                                      width="100"
                                                      viewBox="0 0 268 268"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g opacity="0.01">
                                                        <path
                                                          d="M134 0.939941C60.5108 0.939941 0.939941 60.5108 0.939941 134C0.939941 207.489 60.5108 267.06 134 267.06C207.489 267.06 267.06 207.489 267.06 134C267.06 60.5108 207.489 0.939941 134 0.939941Z"
                                                          fill="#02224e"
                                                        />
                                                        <path
                                                          d="M134 0.939941C60.5108 0.939941 0.939941 60.5108 0.939941 134C0.939941 207.489 60.5108 267.06 134 267.06C207.489 267.06 267.06 207.489 267.06 134C267.06 60.5108 207.489 0.939941 134 0.939941Z"
                                                          stroke="#F2F2F2"
                                                          stroke-width="1.25"
                                                        />
                                                      </g>
                                                      <path
                                                        d="M90.5 102.991H177.5V192.378C177.5 197.714 173.169 202.045 167.833 202.045H100.167C94.8307 202.045 90.5 197.714 90.5 192.378V102.991ZM97.75 110.241V192.378C97.75 193.712 98.8327 194.795 100.167 194.795H167.833C169.167 194.795 170.25 193.712 170.25 192.378V110.241H97.75Z"
                                                        fill="#02224e"
                                                      />
                                                      <path
                                                        d="M88.5944 79.0569L175.594 79.0573C180.93 79.0573 185.261 83.388 185.261 88.724L185.261 108.057L78.9276 108.057L78.9277 88.7236C78.9277 83.3876 83.2584 79.0569 88.5944 79.0569ZM178.011 100.807L178.011 88.724C178.011 87.39 176.928 86.3073 175.594 86.3073L88.5944 86.3069C87.2604 86.3069 86.1777 87.3896 86.1777 88.7236L86.1776 100.807L178.011 100.807Z"
                                                        fill="#02224e"
                                                      />
                                                      <path
                                                        d="M124.361 62.3733L139.828 62.3734C144.097 62.3734 147.561 65.838 147.561 70.1068L147.561 83.5318L116.628 83.5317L116.628 70.1066C116.628 65.8378 120.092 62.3733 124.361 62.3733ZM141.761 70.1067C141.761 69.0395 140.895 68.1734 139.828 68.1734L124.361 68.1733C123.294 68.1733 122.428 69.0395 122.428 70.1067L122.428 77.7317L141.761 77.7318L141.761 70.1067Z"
                                                        fill="#02224e"
                                                      />
                                                      <path
                                                        d="M129.921 126C129.921 123.999 131.545 122.375 133.546 122.375C135.547 122.375 137.171 123.999 137.171 126V183.816C137.171 185.817 135.547 187.441 133.546 187.441C131.545 187.441 129.921 185.817 129.921 183.816V126ZM130.375 126C130.375 123.999 131.999 122.375 134 122.375C136.001 122.375 137.625 123.999 137.625 126V183.816C137.625 185.817 136.001 187.441 134 187.441C131.999 187.441 130.375 185.817 130.375 183.816V126ZM129.771 125.908C129.771 123.907 131.395 122.283 133.396 122.283C135.397 122.283 137.021 123.907 137.021 125.908V183.724C137.021 185.725 135.397 187.349 133.396 187.349C131.395 187.349 129.771 185.725 129.771 183.724V125.908Z"
                                                        fill="#02224e"
                                                      />
                                                    </svg>
                                                  </i>

                                                  <h5 className="mt-4">
                                                    Are you sure you want to
                                                    delete this from cart ?
                                                  </h5>
                                                  <div className="d-flex justify-content-center mt-3">
                                                    <button
                                                      type="button"
                                                      class="main_button me-3"
                                                      data-bs-dismiss="modal"
                                                      onClick={() => {
                                                        removeCart(item);
                                                      }}
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      type="button"
                                                      class="main_button red_button"
                                                      data-bs-dismiss="modal"
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cart_mainSec">
                      <div className="cart_sec"></div>
                      <div className="cart_bottomSec">
                        <div className="cart_actionSec">
                          <ul className="list-inline d-flex mb-0">
                            <li className="me-3"></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="no_product_main">
                  <p>Your cart is empty</p>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="cart_right_body rounded-1 shadow px-3 py-4">
                <h3>Cart Subtotal</h3>
                <div className="cart_total_items d-flex justify-content-between">
                  <div className="cart_total_head">
                    <span className="cart_right_head">Item(s) Total </span>
                  </div>
                  <div className="cart_total_items">
                    <span>
                      £{" "}
                      {newTotal
                        ? newTotal.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })
                        : totalAmount.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="cart_total_price d-flex justify-content-between">
                  <div className="cart_total_head">
                    <span className="cart_right_head">Cart Total</span>
                  </div>
                  <div className="cart_total_price">
                    <span className="cart_price_span">
                      £{" "}
                      {newTotal
                        ? newTotal.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })
                        : totalAmount.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0,
                          })}
                    </span>
                  </div>
                </div>
                <div className="cart_extra mb-3">
                  <span className="cart_right_head">
                    (Excluding shipping fee and tax)
                  </span>
                </div>
                <div className="cart_checkout">
                  <button onClick={handleRedirect} className="btn btn_checkout">
                    Checkout (£
                    {newTotal
                      ? newTotal.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        })
                      : totalAmount.toLocaleString(navigator.language, {
                          minimumFractionDigits: 0,
                        })}
                    )
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddToCart;
