import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../images/image_store.png";
import "../customCss/Footer.css";
import Logo from "../images/logo.png";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";

function Footer() {
  const [smlinks, setSmLinks] = useState([]);
  const [acc, setAcc] = useState(false)
  const auth = AuthService.getCurrentUser();

  const getData = async () => {
    await DataService.getSocialLinks().then((data) => {
      setSmLinks(data?.data?.data);
    });
  };

  useEffect(() => {
    getData();
    check();

  }, []);

  const check = () => {
    if (auth) {
      setAcc(true);
    } else {
      setAcc(false);
    }
  }

  return (
    <>
      <div className="container-fluid main">
        <div className="container common links py-lg-5 py-3 pt-5">
          <div className="row d-flex justify-content-between">
            <div className="col-md-6 col-lg-2 mb-2">
              <div className="navbar-logo" style={{marginBottom: '20px'}}>
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="top_1">
                <h5>Customer Services </h5>
              </div>
              <div className="list_1">
                <ul className="list_ul">
                  {/* <li>
                    <Link className="common_link" to="/faq">
                      Help Center
                    </Link>
                  </li> */}
                  <li>
                    <Link className="common_link" to="/privacy">
                      Policies and Rules
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/buy-and-sell-guide">
                      How to sell and buy
                    </Link>
                  </li>




                  <li>
                    <Link className="common_link" to={acc ? '/complaints' : '/login-page'} >
                      Complaints
                    </Link>
                  </li>

                </ul>
              </div>

              {/* <div className="report_abuse">
              <div className="modal fade" id="exampleModalReport" tabindex="-1" aria-labelledby="exampleModalEditLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl new_report">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h1 className="modal-title fs-5" id="exampleModalEditLabel"><Link to="/">Report abuse</Link></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body px-1 px-lg-4">
                <div className="report_area">
                  <div className="report_main">
                    <input type="file" />
                    <label>Reason</label>
                    <textarea placeholder="Enter your text..."></textarea>
                    <button className="submit_report">Submit</button>

                  </div>
                 
                </div>

                </div>

              </div>
            </div>
          </div>
              </div> */}
            </div>
            <div className="col-md-6  col-lg-2">
              <div className="top_2">
                <h5>About us</h5>
              </div>
              <div className="list_1">
                <ul>
                  <li>
                    <Link className="common_link" to="/about">
                      About Dealt it.com
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/our-vision">
                      Our Vision
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/our-story">
                      Our Story
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="common_link" to="/careers">
                      Careers
                    </Link>
                  </li> */}
                  <li>
                    <Link className="common_link" to="/blog">
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="top_4">
                <h5>DEALITT Payment Method</h5>
              </div>
              <div className="list_1">
                <ul>
                  <li>
                    <Link className="common_link" to="/payment-transaction-guide">
                      Payment Method Help
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/payment-methods">
                      Payment Options
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6  col-lg-2">
              <div className="top_5">
                <h5>Let Us Help You</h5>
              </div>
              <div className="list_1">
                <ul>
                  <li>
                    <Link className="common_link" to="/myorders">
                      Track / View Orders
                    </Link>
                  </li>
                  <li>

                    <Link className="common_link" to="/user-profile">
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/return-policy">
                      Return and Replacement
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container common py-4">
          <div className="row justify-content-end">
            <div className="col-md-5 mt-lg-0 mt-4">
              <div className="icons d-flex footLink align-items-center">
                <h6 className="pe-3 m-0">Follow us:</h6>
                <ul className="m-0 d-flex icon_main ">
                  <li className="icon-list me-2">
                    <Link to={smlinks?.facebook ? smlinks?.facebook : "#"} target="_blank">
                      <i class="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.instagram ? smlinks?.instagram : "#"} target="_blank">
                      <i class="fab fa-instagram"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.twitter ? smlinks?.twitter : "#"} target="_blank">
                      <i class="fa-brands fa-x-twitter"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.linkedin ? smlinks?.linkedin : "#"} target="_blank">
                      <i class="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.youtube ? smlinks?.youtube : "#"} target="_blank">
                      <i class="fab fa-youtube"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.pinterest ? smlinks?.pinterest : "#"}>
                      <i class="fab fa-pinterest" target="_blank"></i>
                    </Link>
                  </li>

                  <li className="icon-list me-2">
                    <Link to={smlinks?.weibo ? smlinks?.weibo : "#"}>
                      <i class="fab fa-weibo" target="_blank"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.tiktok ? smlinks?.tiktok : "#"}>
                      <i class="fab fa-tiktok" target="_blank"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
