import React from "react";
import "../../../customCss/OurStory.css";
import Portrait from "../../../images/dealitt low cost.jpeg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const OurStoryDealit = () => {
  return (
    <>
      <section class="storySec">
        <div className="container my-5 our_storyFlex">
          <div className="row g-4 align-items-stretch">
            <div className="col-sm-12">
              <p className="text-center m-0 text-dark">
                DEALITT, part of A&K Innovation LTD (Registration Number:
                15028375), is a dynamic software development and digital
                e-commerce company dedicated to revolutionizing the pre-loved
                and sustainable fashion industry. Founded in 2023, DEALITT is
                committed to building cutting-edge technology that simplifies
                buying and selling fashion items, fosters sustainability, and
                supports families. DEALITT’s mission is rooted in creating
                opportunities for people worldwide by leveraging innovative
                solutions to make the process of discovering, buying, and
                selling pre-loved fashion effortless, efficient, and impactful.
              </p>
            </div>
          </div>
        </div>
        <div className="container my-5 our_storyFlex">
          <div className="row g-4 align-items-stretch">
            <div className="col-sm-3">
              <div className="stickImg">
                <img className="w-100" src={Portrait} alt="our-story" />
                <p className="text-center mt-4">
                Together, we’re creating a world where fashion connects people,
                supports families, and builds a sustainable future for
                generations to come.
              </p>
              </div>
            </div>
            <div className="col-sm-9 stickText">
              <Accordion  preExpanded={['a']}>
                <AccordionItem uuid='a'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Our Core Values: Simplicity, Efficiency, and
                      Sustainability
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      At DEALITT, we believe that simplicity and efficiency
                      should drive every aspect of the user experience. Our
                      platform connects buyers and sellers seamlessly, helping
                      individuals find the perfect pre-loved fashion items while
                      reducing waste and promoting a circular economy.
                      Sustainability is at the heart of what we do, and we’re
                      dedicated to fostering a future where fashion choices
                      benefit both people and the planet.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Bridging Gaps and Building Connections
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      We saw a gap in the e-commerce landscape where social and
                      technological barriers left many struggling to access
                      quality fashion. DEALITT addresses this by providing a
                      space where buyers and sellers can communicate
                      effectively, ensuring that everyone—from families seeking
                      affordable options to sellers looking to share their
                      unique pieces—feels empowered and supported. Our platform
                      enhances search quality and connections, making it easier
                      to discover high-quality, pre-loved fashion tailored to
                      each individual’s needs.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Empowering Communities and Supporting Families
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      DEALITT is more than just an e-commerce platform; it’s a
                      movement to empower families and communities. By
                      supporting the sale of pre-loved fashion, we help families
                      save money, earn extra income, and contribute to a
                      sustainable future. Every item sold on DEALITT carries a
                      story, and we’re proud to play a part in extending its
                      life while reducing the environmental footprint of
                      fashion.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Responsibility and Fairness: The Pillars of Our Business
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      We are committed to building our business with the utmost
                      responsibility. This means establishing fair policies and
                      processes that benefit our clients, staff, and the
                      environment. By promoting pre-loved fashion, we create
                      opportunities for individuals and businesses while
                      addressing economic disparities. Our vision extends to
                      economically disadvantaged regions, where we aim to create
                      opportunities that improve quality of life and reduce
                      poverty.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Fostering Equity, Diversity, and Inclusion
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      DEALITT thrives on the diversity of its team and
                      community. We understand that an inclusive workplace—one
                      that values equity and diversity—is essential to achieving
                      our mission. By welcoming individuals from diverse
                      backgrounds, we foster a culture of respect, innovation,
                      and collaboration. This commitment ensures that our
                      employees, clients, and partners feel valued and supported
                      in their journey with DEALITT.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Collaboration and Growth: Supporting Our People
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      At DEALITT, we believe that the happiness and well-being
                      of our employees and their families are integral to our
                      success. Collaboration and teamwork define our culture,
                      and we come together to solve challenges and celebrate
                      achievements. We invest in our team’s professional growth,
                      providing access to career development programs and
                      opportunities to build skills and gain global
                      perspectives. By nurturing our employees, we create a
                      ripple effect that benefits families, communities, and the
                      company as a whole.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Innovating for a Sustainable Future
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      As a technology-driven company, we continuously invest in
                      advanced cloud infrastructure and innovative solutions to
                      enhance our systems. Sustainability is a guiding principle
                      in our operations, and we encourage our team to adopt
                      technologies that align with environmental, economic, and
                      social goals. By reducing waste and promoting pre-loved
                      fashion, we contribute to a healthier planet while
                      improving lives and economies worldwide.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Our Vision: A Better Future Through Fashion
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="m-0 text-dark">
                      DEALITT’s vision is to redefine how fashion is consumed by
                      making pre-loved clothing accessible, affordable, and
                      desirable. We believe that every purchase on our platform
                      is a step towards a more sustainable and equitable future.
                      By prioritizing simplicity, efficiency, and
                      sustainability, DEALITT empowers individuals to make
                      meaningful choices that support their families,
                      communities, and the environment.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurStoryDealit;
